import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './App.css'; // Import your custom CSS

const LoginForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const cleanPhoneNumber = (number) => {
    if (number.startsWith('+1-')) {
      number = number.slice(3);
    }
    if (number.startsWith('1') && number.length > 10) {
      number = number.slice(1);
    }

    return number;
  };

  const handleLogin = async () => {
    if (!executeRecaptcha) {
      setError('reCAPTCHA not yet loaded');
      return;
    }

    try {
      const token = await executeRecaptcha('login');
      if (!token) {
        setError('Please complete the reCAPTCHA');
        return;
      }

      // Send the token to your backend for verification
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api.ombrexviewer.ombrex.app';
      const captchaResponse = await axios.post(`${apiUrl}/verify-captcha`, { token });

      if (captchaResponse.data.success) {
        const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
        const tenantResponse = await axios.get(`${apiUrl}/get_tenant_url/${cleanedPhoneNumber}`);
	console.log(tenantResponse)
        const url = tenantResponse.data;

        if (tenantResponse.status === 200 && url) {
          window.location.href = encodeURI(url);
        } else {
          setError('Phone number not found');
        }
      } else {
        setError('reCAPTCHA verification failed');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('Phone number not found');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <Container fluid className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 4, offset: 4 }}>
          <div className="login-box p-4 shadow rounded bg-white">
            <div className="text-center mb-4">
              <img src="/login_logo.png" alt="Logo" className="img-fluid" />
            </div>
            <h2 className="text-center mb-4">Login</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form>
              <Form.Group controlId="formPhoneNumber">
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" className="w-100 mt-3" onClick={handleLogin}>
                Login
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LecHBAqAAAAAP6BzFdWWozFbOcAdg75Pk4om6cs">
      <LoginForm />
    </GoogleReCaptchaProvider>
  );
};

export default App;
